import React, { useEffect, useState, useCallback } from 'react';
import './PromoterDashboard.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement } from 'chart.js';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import { Edit, Plus, Eye, ChevronDown, MoreVertical, Download } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Form, Alert, Row, Col, Dropdown } from 'react-bootstrap';
import { Map } from 'pigeon-maps';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../components/ErrorMessage';
import EventPaymentFlow from '../components/EventPaymentFlow';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement);

// Add this function at the top of your component or in a separate utility file
const getStatusStyle = (status) => {
  const baseStyle = {
    display: 'inline-block',
    padding: '6px 12px',
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white',
  };

  const statusColors = {
    approved: 'rgba(76, 175, 80, 0.8)',  // Green with 80% opacity
    pending: 'rgba(255, 165, 0, 0.8)',   // Orange with 80% opacity
    rejected: 'rgba(244, 67, 54, 0.8)',  // Red with 80% opacity
    canceled: 'rgba(158, 158, 158, 0.8)', // Gray with 80% opacity
    postponed: 'rgba(33, 150, 243, 0.8)', // Blue with 80% opacity
    completed: 'rgba(103, 58, 183, 0.8)', // Purple with 80% opacity
    disabled: 'rgba(121, 85, 72, 0.8)',   // Brown with 80% opacity
  };

  return {
    ...baseStyle,
    backgroundColor: statusColors[status.toLowerCase()] || 'rgba(0, 0, 0, 0.8)',
  };
};

const provinces = [
  'Maputo Cidade',
  'Maputo Província',
  'Gaza',
  'Inhambane',
  'Sofala',
  'Manica',
  'Tete',
  'Zambezia',
  'Nampula',
  'Cabo Delgado',
  'Niassa'
];

// Move constants outside component
const DEFAULT_ZOOM = 10;
const DEFAULT_MAP_CENTER = [-25.924282, 32.569369]; // Default coordinates

const PromoterDashboard = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventAnalytics, setSelectedEventAnalytics] = useState(null);
  const [overallAnalytics, setOverallAnalytics] = useState(null);
  const [timeRange, setTimeRange] = useState('all');
  const [activeTab, setActiveTab] = useState('management');
  const [showEventModal, setShowEventModal] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [eventForm, setEventForm] = useState({
    title: '',
    subtitle: '',
    description: '',
    location: '',
    city: '',
    latitude: '',
    longitude: '',
    category: '',
    date: '',
    time: '',
    duration: '',
    venue_capability: '',
    price: '',
    ticket: '',
    image: null,
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const [isEventListOpen, setIsEventListOpen] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [mapCenter, setMapCenter] = useState(DEFAULT_MAP_CENTER);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [locationError, setLocationError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [showPostponeModal, setShowPostponeModal] = useState(false);
  const [postponeEventId, setPostponeEventId] = useState(null);
  const [newEventDate, setNewEventDate] = useState('');
  const { t } = useTranslation();
  const [apiError, setApiError] = useState(null);
  const [showPaymentFlow, setShowPaymentFlow] = useState(false);
  const [newEventId, setNewEventId] = useState(null);
  const [selectedEventForPayment, setSelectedEventForPayment] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);

  const categories = ['Music', 'Art', 'Food', 'Sports', 'Theater', 'Technology', 'Education', 'Business', 'Health', 'Other'];

  const fetchPromoterEvents = useCallback(async () => {
    try {
      const response = await api.get('/dashboard/events');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching promoter events:', error);
    }
  }, []);

  useEffect(() => {
    fetchPromoterEvents();
  }, [fetchPromoterEvents]);

  const handleEventSelect = async (eventData) => {
    const event = eventData.event;
    if (!event?.id) {
      console.error('Invalid event or event ID');
      return;
    }

    setSelectedEvent(event);
    try {
      const response = await api.get(`/dashboard/events/${event.id}/analytics`);
      setSelectedEventAnalytics(response.data);
    } catch (error) {
      console.error('Error fetching event analytics:', error);
    }
  };

  const renderKPIs = () => {
    if (!selectedEventAnalytics) return null;

    return (
      <div className="kpi-container">
        <div className="kpi-item">
          <h3>Total Sign-ups</h3>
          <p>{selectedEventAnalytics.interest_count || 0}</p>
        </div>
        <div className="kpi-item">
          <h3>Average Rating</h3>
          <p>{selectedEventAnalytics.average_rating ? selectedEventAnalytics.average_rating.toFixed(2) : 'N/A'}</p>
        </div>
        <div className="kpi-item">
          <h3>Total Reviews</h3>
          <p>{selectedEventAnalytics.reviews ? selectedEventAnalytics.reviews.length : 0}</p>
        </div>
      </div>
    );
  };

  const renderRatingDistribution = () => {
    if (!selectedEventAnalytics) return null;

    const data = {
      labels: ['1 Star', '2 Stars', '3 Stars', '4 Stars', '5 Stars'],
      datasets: [
        {
          data: Object.values(selectedEventAnalytics.rating_counts),
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#4BC0C0',
            '#9966FF'
          ],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Rating Distribution',
        },
      },
    };

    return (
      <div className="chart-container">
        <Pie data={data} options={options} />
      </div>
    );
  };

  const renderReviewsOverTime = () => {
    if (!selectedEventAnalytics) return null;

    const reviewsByMonth = {};
    selectedEventAnalytics.reviews.forEach(review => {
      const date = new Date(review.created_at);
      const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`;
      reviewsByMonth[monthYear] = (reviewsByMonth[monthYear] || 0) + 1;
    });

    const data = {
      labels: Object.keys(reviewsByMonth),
      datasets: [
        {
          label: 'Number of Reviews',
          data: Object.values(reviewsByMonth),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Reviews Over Time',
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Number of Reviews'
          }
        },
        x: {
          title: {
            display: true,
            text: 'Month/Year'
          }
        }
      }
    };

    return (
      <div className="chart-container">
        <Bar data={data} options={options} />
      </div>
    );
  };

  const renderRecentReviews = () => {
    if (!selectedEventAnalytics) return null;

    return (
      <div className="recent-reviews">
        <h3>Recent Reviews</h3>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Rating</th>
              <th>Comment</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {selectedEventAnalytics.reviews.slice(0, 5).map((review, index) => (
              <tr key={index}>
                <td>{`${review.user.first_name} ${review.user.last_name}`}</td>
                <td>{review.rating}</td>
                <td>{review.review_text}</td>
                <td>{new Date(review.created_at).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderOverallAnalytics = () => {
    if (!overallAnalytics) return null;

    return (
      <div className="overall-analytics">
        <div className="time-range-selector">
          <select value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
            <option value="all">All Time</option>
            <option value="week">Last Week</option>
            <option value="month">Last Month</option>
            <option value="year">Last Year</option>
          </select>
        </div>
        <div className="kpi-container">
          <div className="kpi-item">
            <h3>Total Events</h3>
            <p>{overallAnalytics.total_events}</p>
          </div>
          <div className="kpi-item">
            <h3>Total Interest</h3>
            <p>{overallAnalytics.total_interest}</p>
          </div>
          <div className="kpi-item">
            <h3>Average Rating</h3>
            <p>{overallAnalytics.average_rating.toFixed(2)}</p>
          </div>
        </div>
        {renderDemographics()}
      </div>
    );
  };

  const renderDemographics = () => {
    if (!overallAnalytics || !overallAnalytics.demographics) {
      return <p>No demographic data available</p>;
    }

    const { demographics, age_distribution } = overallAnalytics;

    const genderData = {
      labels: [
        t('promoterDashboard.demographics.gender.male'),
        t('promoterDashboard.demographics.gender.female'),
        t('promoterDashboard.demographics.gender.other'),
        t('promoterDashboard.demographics.gender.unknown')
      ],
      datasets: [
        {
          data: [
            demographics.male?.count || 0, 
            demographics.female?.count || 0, 
            demographics.other?.count || 0,
            demographics.unknown?.count || 0
          ],
          backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
        },
      ],
    };

    const ageData = {
      labels: ['Under 18', '18-24', '25-34', '35-44', '45-54', '55+'],
      datasets: [
        {
          label: 'Age Distribution',
          data: [
            age_distribution['Under 18'] || 0,
            age_distribution['18-24'] || 0,
            age_distribution['25-34'] || 0,
            age_distribution['35-44'] || 0,
            age_distribution['45-54'] || 0,
            age_distribution['55+'] || 0,
          ],
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    };

    const ageOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: t('promoterDashboard.analytics.labels.users')
          }
        },
        x: {
          title: {
            display: true,
            text: t('promoterDashboard.demographics.age.group')
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: t('promoterDashboard.demographics.age.title')
        }
      }
    };

    return (
      <div className="demographics">
        <h3>{t('promoterDashboard.demographics.title')}</h3>
        <div className="charts-container">
          <div className="chart-container">
            <h4>{t('promoterDashboard.demographics.gender.title')}</h4>
            <div style={{ height: '300px', width: '100%' }}>
              <Pie data={genderData} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>
          </div>
          <div className="chart-container">
            <h4>{t('promoterDashboard.demographics.age.title')}</h4>
            <div style={{ height: '300px', width: '100%' }}>
              <Bar data={ageData} options={ageOptions} />
            </div>
          </div>
        </div>
        <div className="demographics-details">
          <table className="demographics-table">
            <thead>
              <tr>
                <th>{t('promoterDashboard.demographics.gender.table.gender')}</th>
                <th>{t('promoterDashboard.demographics.gender.table.count')}</th>
                <th>{t('promoterDashboard.demographics.gender.table.avgAge')}</th>
              </tr>
            </thead>
            <tbody>
              {['male', 'female', 'other', 'unknown'].map((gender) => (
                <tr key={gender}>
                  <td>{t(`promoterDashboard.demographics.gender.${gender}`)}</td>
                  <td>{demographics[gender]?.count || 0}</td>
                  <td>{demographics[gender]?.avg_age ? 
                    `${demographics[gender].avg_age.toFixed(1)}` : 
                    t('promoterDashboard.demographics.age.notAvailable')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const validateForm = () => {
    const errors = {};
    
    // Add date validation
    if (eventForm.date) {
      const selectedDate = new Date(`${eventForm.date}T${eventForm.time || '00:00'}`);
      const now = new Date();
      
      if (selectedDate < now) {
        errors.date = "Event date cannot be in the past";
      }
    }
    
    // Existing validations
    if (!eventForm.title || eventForm.title.length > 150) {
      errors.title = "Title is required and must be less than 150 characters";
    }
    if (!eventForm.description || eventForm.description.length < 10) {
      errors.description = "Description is required and must be at least 10 characters long";
    }
    if (!eventForm.location || eventForm.location.length < 5) {
      errors.location = "Location is required and must be at least 5 characters long";
    }
    if (!eventForm.category) {
      errors.category = "Category is required";
    }
    if (!eventForm.date) {
      errors.date = "Date is required";
    }
    if (eventForm.price === undefined || eventForm.price < 0) {
      errors.price = "Price is required and must be 0 or greater";
    }
    if (eventForm.duration && eventForm.duration <= 0) {
      errors.duration = "Duration must be greater than 0";
    }
    if (!editingEvent && !eventForm.image) {
      errors.image = "Event image is required";
    }
    
    return errors;
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formData = new FormData();
    Object.keys(eventForm).forEach(key => {
      if (key === 'image' && eventForm.image instanceof File) {
        formData.append('image', eventForm.image);
      } else if (eventForm[key] != null) {
        formData.append(key, eventForm[key]);
      }
    });

    try {
      let response;
      if (editingEvent) {
        response = await api.put(`/events/${editingEvent.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await api.post('/events/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      const data = response.data;

      if (!editingEvent) {
        setNewEventId(data.id);
        setShowEventModal(false);
        setShowPaymentFlow(true);
      } else {
        setSuccessMessage('Event updated successfully!');
        setShowEventModal(false);
        fetchPromoterEvents();
      }
    } catch (error) {
      console.error('Error saving event:', error);
      setApiError(error.response?.data?.detail || 'Failed to save event');
    }
  };

  const handlePaymentComplete = async (paymentResponse) => {
    try {
      await api.put(`/events/${newEventId}/payment-complete`);
      setShowPaymentFlow(false);
      setSuccessMessage(t('payment.messages.eventCreated'));
      fetchPromoterEvents();
    } catch (error) {
      console.error('Error updating event status:', error);
      setApiError(t('payment.messages.eventUpdateFailed'));
    }
  };

  const handleEventFormChange = (e) => {
    const { name, value, type, files } = e.target;
    
    if (type === 'file') {
      setEventForm(prev => ({
        ...prev,
        [name]: files[0] // Store the actual File object
      }));
    } else {
      setEventForm(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    setEventForm({
      title: event.title,
      subtitle: event.subtitle || '',
      description: event.description,
      location: event.location,
      city: event.city || '',
      latitude: event.latitude || '',
      longitude: event.longitude || '',
      category: event.category,
      date: new Date(event.date).toISOString().split('T')[0],
      time: new Date(event.date).toTimeString().split(' ')[0].slice(0, 5),
      duration: event.duration || '',
      venue_capability: event.venue_capability || '',
      ticket: event.ticket || '',
      image: event.image || '',
      price: event.price
    });
    setShowEventModal(true);
  };

  const handleViewEvent = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleEventAction = async (eventId, action) => {
    if (action === 'postpone') {
      setPostponeEventId(eventId);
      setShowPostponeModal(true);
      return;
    }

    if (window.confirm(t('promoterDashboard.events.confirmations.' + action))) {
      try {
        let response;
        switch (action) {
          case 'cancel':
            response = await api.put(`/events/${eventId}/cancel`);
            break;
          case 'delete':
            response = await api.delete(`/events/${eventId}/delete`);
            break;
          default:
            throw new Error('Invalid action');
        }
        if (response.status === 200 || response.status === 204) {
          fetchPromoterEvents();
          setSuccessMessage(`Event successfully ${action}ed`);
        }
      } catch (error) {
        console.error(`Error ${action}ing event:`, error);
        setFormErrors({ general: `Failed to ${action} event. Please try again.` });
      }
    }
  };

  const handlePostponeEvent = async () => {
    try {
      const response = await api.put(`/events/${postponeEventId}/postpone`, { new_date: newEventDate });
      if (response.status === 200) {
        setShowPostponeModal(false);
        fetchPromoterEvents();
        // You might want to show a success message here
      }
    } catch (error) {
      console.error('Error postponing event:', error);
      // You might want to show an error message here
    }
  };

  const initialFormState = {
    title: '',
    subtitle: '',
    description: '',
    location: '',
    city: '',
    latitude: '',
    longitude: '',
    category: '',
    date: '',
    time: '',
    duration: '',
    venue_capability: '',
    price: '',
    ticket: '',
    image: null,
  };

  const handleAddNewEvent = () => {
    setEditingEvent(null);
    setEventForm(initialFormState);
    setFormErrors({});
    setShowEventModal(true);
  };

  const renderEventManagement = () => {
    return (
      <div className="event-management">
        <button className="btn-add-event" onClick={handleAddNewEvent}>
          <Plus size={20} /> {t('promoterDashboard.events.addNew')}
        </button>
        <table className="event-table">
          <thead>
            <tr>
              <th>{t('promoterDashboard.events.table.title')}</th>
              <th>{t('promoterDashboard.events.table.date')}</th>
              <th>{t('promoterDashboard.events.table.location')}</th>
              <th>{t('promoterDashboard.events.table.province')}</th>
              <th>{t('promoterDashboard.events.table.price')}</th>
              <th>{t('promoterDashboard.events.table.interestCount')}</th>
              <th>{t('promoterDashboard.events.table.averageRating')}</th>
              <th>{t('promoterDashboard.events.table.status')}</th>
              <th>{t('promoterDashboard.events.table.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {events.map((eventData) => {
              const event = eventData.event;
              const isApproved = event.status === 'Approved';
              const isCanceled = event.status === 'Canceled';
              const needsPayment = event.status === 'Payment';
              const hasPayment = event.status === 'Pending' || event.status === 'Approved';

              return (
                <tr key={event.id}>
                  <td>{event.title}</td>
                  <td>
                    <FontAwesomeIcon icon={faCalendarAlt} /> {new Date(event.date).toLocaleDateString()}
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> {event.location}
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> {event.city}
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faDollarSign} /> {event.price}
                  </td>
                  <td>{event.interest_count}</td>
                  <td>{event.average_rating ? event.average_rating.toFixed(1) : 'N/A'}</td>
                  <td>
                    <span style={getStatusStyle(event.status)}>
                      {event.status}
                    </span>
                  </td>
                  <td>
                    <button className="btn-view" onClick={() => handleViewEvent(event.id)}>
                      <Eye size={16} />
                    </button>
                    <button className="btn-edit" onClick={() => handleEditEvent(event)}>
                      <Edit size={16} />
                    </button>
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id={`dropdown-${event.id}`}>
                        <MoreVertical size={16} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {needsPayment && (
                          <>
                            <Dropdown.Item 
                              onClick={() => {
                                setNewEventId(event.id);
                                setShowPaymentFlow(true);
                              }}
                              className="payment-action"
                            >
                              {t('payment.payNow')}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                          </>
                        )}
                        {hasPayment && (
                          <>
                            <Dropdown.Item 
                              onClick={() => handleDownloadReceipt(event)}
                              className="receipt-action"
                            >
                              <Download size={14} className="me-2" />
                              {t('payment.downloadReceipt')}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                          </>
                        )}
                        <Dropdown.Item onClick={() => handleEventAction(event.id, 'cancel')} disabled={!isApproved}>
                          {t('promoterDashboard.events.actions.cancel')}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleEventAction(event.id, 'postpone')} disabled={!isApproved || isCanceled}>
                          {t('promoterDashboard.events.actions.postpone')}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => handleEventAction(event.id, 'delete')} className="text-danger">
                          {t('promoterDashboard.events.actions.delete')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTabs = () => (
    <div className="dashboard-tabs">
      <button 
        className={`tab-button ${activeTab === 'management' ? 'active' : ''}`}
        onClick={() => setActiveTab('management')}
      >
        {t('promoterDashboard.tabs.management')}
      </button>
      <button 
        className={`tab-button ${activeTab === 'overall' ? 'active' : ''}`}
        onClick={() => setActiveTab('overall')}
      >
        {t('promoterDashboard.tabs.overallAnalytics')}
      </button>
      <button 
        className={`tab-button ${activeTab === 'event' ? 'active' : ''}`}
        onClick={() => setActiveTab('event')}
      >
        {t('promoterDashboard.tabs.eventAnalytics')}
      </button>
    </div>
  );

  const renderEventDropdown = () => {
    return (
      <div className="event-dropdown">
        <button 
          className="event-dropdown-toggle" 
          onClick={() => setIsEventListOpen(!isEventListOpen)}
        >
          {selectedEvent ? selectedEvent.title : t('promoterDashboard.eventSelect.placeholder')} <ChevronDown size={20} />
        </button>
        {isEventListOpen && (
          <ul className="event-dropdown-menu">
            {events.map((eventData, index) => {
              const event = eventData.event;
              return (
                <li 
                  key={event.id || `event-${index}`} 
                  onClick={() => {
                    handleEventSelect(eventData);
                    setIsEventListOpen(false);
                  }}
                >
                  <p>{event.title || t('promoterDashboard.eventSelect.untitled')}</p>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (activeTab === 'overall') {
      return renderOverallAnalytics();
    } else if (activeTab === 'event') {
      return (
        <>
          {renderEventDropdown()}
          {selectedEvent && selectedEventAnalytics ? (
            <>
              <h2>{selectedEvent.title} Analytics</h2>
              {renderKPIs()}
              <div className="charts-container">
                {renderRatingDistribution()}
                {renderReviewsOverTime()}
              </div>
              {renderRecentReviews()}
            </>
          ) : (
            <p>Select an event to view detailed analytics</p>
          )}
        </>
      );
    } else if (activeTab === 'management') {
      return renderEventManagement();
    }
  };

  const handleMapClick = ({ latLng }) => {
    setEventForm(prev => ({
      ...prev,
      latitude: latLng[0].toFixed(6),
      longitude: latLng[1].toFixed(6)
    }));
    setMapCenter(latLng);
    setShowMapModal(false);
  };

  const handlePaymentClick = (event) => {
    setSelectedEventForPayment(event);
    setNewEventId(event.id);
    setShowPaymentFlow(true);
  };

  const getUserLocation = useCallback(() => {
    if (!mapInstance || !showMapModal) return; // Only get location if map is shown

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newCenter = [position.coords.latitude, position.coords.longitude];
          setMapCenter(newCenter);
          mapInstance.setView(newCenter, DEFAULT_ZOOM);
          setLocationError(null);
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocationError("Unable to get your location. Using default map view.");
        }
      );
    }
  }, [mapInstance, showMapModal]);

  // Update map reference when it's created
  const onMapCreated = (map) => {
    setMapInstance(map);
  };

  useEffect(() => {
    if (showMapModal && !mapInstance) {
      getUserLocation();
    }
  }, [showMapModal, mapInstance, getUserLocation]);

  const fetchOverallAnalytics = useCallback(async () => {
    try {
      const response = await api.get(`/dashboard/overall-analytics?time_range=${timeRange}`);
      setOverallAnalytics(response.data);
    } catch (error) {
      console.error('Error fetching overall analytics:', error);
    }
  }, [timeRange]);

  useEffect(() => {
    fetchOverallAnalytics();
  }, [fetchOverallAnalytics]);

  const handlePayLater = () => {
    setShowPaymentFlow(false);  // Close the payment modal
    setSuccessMessage(t('payment.messages.paymentDeferred')); // Optional: show a message
  };

  const handleDownloadReceipt = async (event) => {
    try {
      // Get the PDF receipt from the backend
      const response = await api.get(
        `/api/v1/payments/event/${event.id}/receipt`,
        { responseType: 'blob' }  // Important for handling PDF data
      );

      // Create a blob URL and trigger download
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `receipt_${event.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading receipt:', error);
      setApiError(t('payment.errors.receiptDownloadFailed'));
    }
  };

  return (
    <div className="promoter-dashboard">
      {apiError && (
        <ErrorMessage 
          message={apiError} 
          type="error" 
          onClose={() => setApiError(null)} 
        />
      )}
      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
          {successMessage}
        </Alert>
      )}
      <div className="dashboard-content">
        <div className="analytics-container">
          {renderTabs()}
          {renderContent()}
        </div>
      </div>

      {/* Event Form Modal */}
      <Modal show={showEventModal} onHide={() => setShowEventModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {editingEvent ? t('promoterDashboard.eventForm.edit.title') : t('promoterDashboard.eventForm.create.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.entries(formErrors).map(([field, error]) => (
            <ErrorMessage 
              key={field}
              message={error} 
              type="warning" 
              onClose={() => setFormErrors(prev => {
                const newErrors = { ...prev };
                delete newErrors[field];
                return newErrors;
              })} 
            />
          ))}
          
          <Form onSubmit={handleEventSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.title.label')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={eventForm.title}
                    onChange={handleEventFormChange}
                    required
                    placeholder={t('promoterDashboard.eventForm.title.placeholder')}
                    isInvalid={!!formErrors.title}
                  />
                  {formErrors.title && (
                    <ErrorMessage message={formErrors.title} type="warning" />
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.subtitle.label')}</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="subtitle" 
                    value={eventForm.subtitle} 
                    onChange={handleEventFormChange} 
                    placeholder={t('promoterDashboard.eventForm.subtitle.placeholder')} 
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>{t('promoterDashboard.eventForm.description.label')}</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3} 
                name="description" 
                value={eventForm.description} 
                onChange={handleEventFormChange} 
                required 
                placeholder={t('promoterDashboard.eventForm.description.placeholder')} 
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('promoterDashboard.eventForm.category.label')}</Form.Label>
              <Form.Select 
                name="category" 
                value={eventForm.category} 
                onChange={handleEventFormChange} 
                required
              >
                <option value="">{t('promoterDashboard.eventForm.category.placeholder')}</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {t(`categories.${category}`)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <h5 className="mt-4">{t('promoterDashboard.eventForm.location.title')}</h5>
            <Form.Label>{t('promoterDashboard.eventForm.location.label')}</Form.Label>
            <Form.Control 
              type="text" 
              name="location" 
              value={eventForm.location} 
              onChange={handleEventFormChange} 
              required 
              placeholder={t('promoterDashboard.eventForm.location.placeholder')} 
            />

            <Form.Group className="mb-3">
              <Form.Label>{t('promoterDashboard.eventForm.location.province')}</Form.Label>
              <Form.Select 
                name="city" 
                value={eventForm.city} 
                onChange={handleEventFormChange}
                required
              >
                <option value="">{t('promoterDashboard.eventForm.location.provinceSelect')}</option>
                {provinces.map((province) => (
                  <option key={province} value={province}>{province}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.location.latitude')}</Form.Label>
                  <Form.Control 
                    type="number" 
                    name="latitude" 
                    value={eventForm.latitude} 
                    onChange={handleEventFormChange} 
                    step="any" 
                    readOnly 
                    placeholder={t('promoterDashboard.eventForm.location.selectOnMap')} 
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.location.longitude')}</Form.Label>
                  <Form.Control 
                    type="number" 
                    name="longitude" 
                    value={eventForm.longitude} 
                    onChange={handleEventFormChange} 
                    step="any" 
                    readOnly 
                    placeholder={t('promoterDashboard.eventForm.location.selectOnMap')} 
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="secondary" onClick={() => setShowMapModal(true)} className="mb-3">
              {t('promoterDashboard.eventForm.location.selectOnMap')}
            </Button>

            <h5 className="mt-4">{t('promoterDashboard.eventForm.details.title')}</h5>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.details.date')}</Form.Label>
                  <Form.Control 
                    type="date" 
                    name="date" 
                    value={eventForm.date} 
                    onChange={handleEventFormChange} 
                    min={new Date().toISOString().split('T')[0]}
                    required 
                    isInvalid={!!formErrors.date}
                  />
                  {formErrors.date && (
                    <Form.Control.Feedback type="invalid">
                      {t('promoterDashboard.eventForm.validation.pastDate')}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.details.time')}</Form.Label>
                  <Form.Control 
                    type="time" 
                    name="time" 
                    value={eventForm.time} 
                    onChange={handleEventFormChange}
                    step="1800"  // 30 minutes in seconds
                    required 
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.details.duration.label')}</Form.Label>
                  <Form.Control 
                    type="number" 
                    name="duration" 
                    value={eventForm.duration} 
                    onChange={handleEventFormChange} 
                    min="0" 
                    step="0.5" 
                    placeholder={t('promoterDashboard.eventForm.details.duration.placeholder')} 
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.venueCapacity.label')}</Form.Label>
                  <Form.Control 
                    type="number" 
                    name="venue_capability" 
                    value={eventForm.venue_capability} 
                    onChange={handleEventFormChange} 
                    min="0" 
                    placeholder={t('promoterDashboard.eventForm.venueCapacity.placeholder')} 
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('promoterDashboard.eventForm.price.label')}*</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={eventForm.price}
                    onChange={handleEventFormChange}
                    required
                    min="0"
                    step="0.01"
                    placeholder={t('promoterDashboard.eventForm.price.placeholder')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h5 className="mt-4">{t('promoterDashboard.eventForm.additionalInfo.title')}</h5>
            <Form.Group className="mb-3">
              <Form.Label>{t('promoterDashboard.eventForm.ticket.label')}</Form.Label>
              <Form.Control 
                type="url" 
                name="ticket" 
                value={eventForm.ticket} 
                onChange={handleEventFormChange} 
                placeholder={t('promoterDashboard.eventForm.ticket.placeholder')} 
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('promoterDashboard.eventForm.image.label')}*</Form.Label>
              <Form.Control 
                type="file" 
                name="image" 
                onChange={handleEventFormChange} 
                accept="image/*"
                isInvalid={!!formErrors.image}
                required
              />
              {formErrors.image && (
                <ErrorMessage message={formErrors.image} type="warning" />
              )}
            </Form.Group>
            
            <Button variant="primary" type="submit" className="mt-3">
              {editingEvent ? t('promoterDashboard.eventForm.buttons.update') : t('promoterDashboard.eventForm.buttons.create')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Map Modal */}
      <Modal show={showMapModal} onHide={() => setShowMapModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('promoterDashboard.mapModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {locationError && <ErrorMessage message={locationError} type="info" />}
          <div style={{ height: '60vh', width: '100%', marginBottom: '1rem' }}>
            <Map
              height="100%"
              center={mapCenter}
              zoom={zoom}
              whenCreated={onMapCreated}
              onBoundsChanged={({ center, zoom }) => {
                setMapCenter(center);
                setZoom(zoom);
              }}
              onClick={handleMapClick}
            />
          </div>
          <Button variant="primary" onClick={getUserLocation}>
            {t('promoterDashboard.mapModal.refreshLocation')}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowMapModal(false)}>
            {t('promoterDashboard.mapModal.close')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Postpone Modal */}
      <Modal show={showPostponeModal} onHide={() => setShowPostponeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('promoterDashboard.postponeModal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>{t('promoterDashboard.postponeModal.newDate')}</Form.Label>
              <Form.Control
                type="date"
                value={newEventDate}
                onChange={(e) => setNewEventDate(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPostponeModal(false)}>
            {t('promoterDashboard.postponeModal.cancel')}
          </Button>
          <Button variant="primary" onClick={handlePostponeEvent}>
            {t('promoterDashboard.postponeModal.confirm')}
          </Button>
        </Modal.Footer>
      </Modal>

      {showPaymentFlow && (
        <Modal show={showPaymentFlow} onHide={() => setShowPaymentFlow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('payment.confirmation')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EventPaymentFlow
              eventId={newEventId}
              eventDetails={selectedEventForPayment}
              onComplete={handlePaymentComplete}
              onPayLater={handlePayLater}
            />
          </Modal.Body>
        </Modal>
      )}

      {events?.some(event => event.status === 'PAYMENT') && (
        <div className="mt-4">
          <h5>{t('payment.pendingEvents')}</h5>
          <table className="table">
            <thead>
              <tr>
                <th>Event</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {events
                .filter(event => event.status === 'PAYMENT')
                .map(event => (
                  <tr key={event.id}>
                    <td>{event.title}</td>
                    <td>{event.price} MT</td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handlePaymentClick(event)}
                      >
                        {t('payment.payNow')}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PromoterDashboard;
