import React from 'react';
import { useTranslation } from 'react-i18next';
import { Mail, Phone, ArrowRight } from 'lucide-react'; // Import icons
import './Help.css';

const Help = () => {
  const { t } = useTranslation();

  return (
    <div className="help-container">
      <div className="help-header">
        <h1>{t('help.title')}</h1>
      </div>

      <section className="help-section">
        <div className="contact-grid">
          {/* Email Contact Card */}
          <div className="contact-card">
            <div className="card-icon">
              <Mail size={24} />
            </div>
            <h3>{t('help.contact.email')}</h3>
            <p>{t('help.contact.emailResponse')}</p>
            <div className="contact-links">
              <a href="mailto:suporte@soevento.com" className="contact-link">
                suporte@soevento.com
                <ArrowRight size={16} />
              </a>
              <a href="mailto:info@soevento.com" className="contact-link">
                info@soevento.com
                <ArrowRight size={16} />
              </a>
            </div>
          </div>

          {/* Phone Contact Card */}
          <div className="contact-card">
            <div className="card-icon">
              <Phone size={24} />
            </div>
            <h3>{t('help.contact.phone')}</h3>
            <p>{t('help.contact.phoneResponse')}</p>
            <div className="contact-links">
              <a href="tel:+258874000234" className="contact-link">
                +258 87 400 0234
                <ArrowRight size={16} />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Help; 