import React, { useState } from 'react';
import './EditProfileModal.css';
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp, FaUserTie } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import api from '../api';

const EditProfileModal = ({ profile, onClose, onUpdate, onDelete }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    first_name: profile.first_name || '',
    last_name: profile.last_name || '',
    short_bio: profile.short_bio || '',
    password: '',
    confirm_password: '',
    facebook: profile.facebook || '',
    twitter: profile.twitter || '',
    instagram: profile.instagram || '',
    cellphone: profile.cellphone || '',
    logo: profile.logo || '',
    role: profile.role,
    account_name: profile.account_name || '',
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  });
  const [error, setError] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, logo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.role === 'promoter' && !formData.account_name) {
      setError(t('editProfile.errors.promoterNameRequired'));
      return;
    }

    if (formData.new_password && formData.new_password !== formData.confirm_new_password) {
      setError(t('editProfile.errors.passwordMismatch'));
      return;
    }

    setShowPasswordConfirmation(true);
  };

  const handleDeleteAccount = async () => {
    if (!profile?.id) {
      console.error('Profile or Profile ID is undefined');
      return;
    }
    try {
      const response = await api.delete(`/users/${profile.id}`);
      if (response.status === 200) {
        onDelete();
        onClose();
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('Failed to delete account. Please try again.');
    }
  };

  const confirmUpdate = async () => {
    if (!formData.current_password) {
      setError('Current password is required to save changes.');
      return;
    }

    try {
      // Verify password
      await api.post('/users/verify-password', {
        user_id: profile.id,
        password: formData.current_password
      });

      // Update profile
      const updateData = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        short_bio: formData.short_bio,
        facebook: formData.facebook,
        twitter: formData.twitter,
        instagram: formData.instagram,
        cellphone: formData.cellphone,
        role: formData.role,
        account_name: formData.account_name,
        current_password: formData.current_password,
        new_password: formData.new_password,
        confirm_new_password: formData.confirm_new_password,
      };

      const response = await api.put(`/users/${profile.id}/update`, updateData);
      onUpdate(response.data);
      onClose();
      
    } catch (error) {
      console.error('Error in confirmUpdate:', error);
      if (error.response?.status === 400) {
        setError('Incorrect password. Please try again.');
      } else {
        setError('An error occurred while updating the profile. Please try again.');
      }
    }
    setShowPasswordConfirmation(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{t('editProfile.title')}</h2>
        {error && <p className="error-message">{error}</p>}
        {showPasswordConfirmation ? (
          <div className="password-confirmation">
            <h3>{t('editProfile.passwordConfirmation.title')}</h3>
            <p>{t('editProfile.passwordConfirmation.description')}</p>
            <input
              type="password"
              name="current_password"
              value={formData.current_password}
              onChange={handleChange}
              placeholder={t('editProfile.passwordConfirmation.placeholder')}
              required
            />
            <div className="confirmation-actions">
              <button onClick={confirmUpdate}>{t('editProfile.passwordConfirmation.confirm')}</button>
              <button onClick={() => setShowPasswordConfirmation(false)}>
                {t('editProfile.passwordConfirmation.cancel')}
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              placeholder={t('editProfile.fields.firstName')}
              required
            />
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              placeholder={t('editProfile.fields.lastName')}
              required
            />
            <textarea
              name="short_bio"
              value={formData.short_bio}
              onChange={handleChange}
              placeholder={t('editProfile.fields.shortBio')}
            ></textarea>
            <div className="social-media-input">
              <FaFacebook />
              <input
                type="text"
                name="facebook"
                value={formData.facebook}
                onChange={handleChange}
                placeholder={t('editProfile.fields.facebook')}
              />
            </div>
            <div className="social-media-input">
              <FaTwitter />
              <input
                type="text"
                name="twitter"
                value={formData.twitter}
                onChange={handleChange}
                placeholder={t('editProfile.fields.twitter')}
              />
            </div>
            <div className="social-media-input">
              <FaInstagram />
              <input
                type="text"
                name="instagram"
                value={formData.instagram}
                onChange={handleChange}
                placeholder={t('editProfile.fields.instagram')}
              />
            </div>
            <div className="social-media-input">
              <FaWhatsapp />
              <input
                type="text"
                name="cellphone"
                value={formData.cellphone}
                onChange={handleChange}
                placeholder={t('editProfile.fields.whatsapp')}
              />
            </div>
            <div className="logo-upload">
              <label htmlFor="logo-input">{t('editProfile.fields.logo.label')}</label>
              <input
                id="logo-input"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
              {formData.logo && (
                <img src={formData.logo} alt="Logo Preview" className="logo-preview" />
              )}
            </div>
            {profile.role !== 'promoter' && (
              <div className="promoter-upgrade">
                <label>
                  <input
                    type="checkbox"
                    name="role"
                    checked={formData.role === 'promoter'}
                    onChange={(e) => setFormData({ 
                      ...formData, 
                      role: e.target.checked ? 'promoter' : 'user',
                      account_name: e.target.checked ? formData.account_name : ''
                    })}
                  />
                  {t('editProfile.fields.promoter.upgrade')}
                </label>
              </div>
            )}
            {formData.role === 'promoter' && (
              <div className="social-media-input">
                <FaUserTie />
                <input
                  type="text"
                  name="account_name"
                  value={formData.account_name}
                  onChange={handleChange}
                  placeholder={t('editProfile.fields.promoter.accountName')}
                  required
                />
              </div>
            )}
      
            <input
              type="password"
              name="new_password"
              value={formData.new_password}
              onChange={handleChange}
              placeholder={t('editProfile.fields.password.new')}
            />
            {formData.new_password && (
              <input
                type="password"
                name="confirm_new_password"
                value={formData.confirm_new_password}
                onChange={handleChange}
                placeholder={t('editProfile.fields.password.confirm')}
                required
              />
            )}
            <div className="modal-actions">
              <button type="submit">{t('editProfile.buttons.save')}</button>
              <button type="button" onClick={onClose}>{t('editProfile.buttons.cancel')}</button>
            </div>
          </form>
        )}
        <div className="delete-account-section">
          <button onClick={() => setShowDeleteConfirmation(true)} className="delete-account-btn">
            {t('editProfile.buttons.deleteAccount')}
          </button>
        </div>
        {showDeleteConfirmation && (
          <div className="delete-confirmation">
            <p>{t('editProfile.deleteConfirmation.message')}</p>
            <button onClick={handleDeleteAccount} className="confirm-delete-btn">
              {t('editProfile.deleteConfirmation.confirm')}
            </button>
            <button onClick={() => setShowDeleteConfirmation(false)} className="cancel-delete-btn">
              {t('editProfile.deleteConfirmation.cancel')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfileModal;